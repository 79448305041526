import BackProjectButton from "src/components/BackProjectButton";
import DescriptionPopup from "./DescriptionPopup"
import TopTabs from "../TopTabs";
import { useState } from "react";

const DocumentationContainer = () => {
        // State to manage the selected dropdown value for each row
        const [selectedValue, setSelectedValue] = useState("Action");

        // Function to handle dropdown item click
        const handleSelect = (value) => {
            setSelectedValue(value);
        };
    
        
        const HeadingDocumentation = "Hours Invested and Cost of Each Project";
    
        const tableDocumentation = [
            {
                uploadedby: `<div class="d-flex align-items-center gap-2">
            <span class="img imgsSet me-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="tooltip-dark" data-bs-title="Amrik Singh">
              <span class="initials d-flex justify-content-center align-items-center w-100 h-100" style="background-color: rgb(154, 217, 38); color: rgb(0, 0, 0);">A</span>
            </span>
            <div class="flex-grow-1">
              <h6 class="text-md mb-0 fw-medium text-primary-600">Amrik Singh</h6>
            </div>
          </div>`,
                type: 'Agreement',
                //   view: '',
                uploadat: '2024-09-30',
                description: 'View',
            }
        ];
    return (
        <>
             <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">
                            <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                <BackProjectButton />
                            </h2>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Documentation</li>
                            </ul>
                            <TopTabs />
                            <div className="table-responsive table-container mt-24">
                                <table className="table basic-border-table mb-0 table-hover">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Uploaded By</th>
                                            <th>Document Type</th>
                                            <th className='text-center'>View Document</th>
                                            <th className='text-center'>Upload At</th>
                                            <th className='text-center'>Description</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableDocumentation.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td dangerouslySetInnerHTML={{ __html: item.uploadedby }} /> {/* Render HTML */}
                                                <td>{item.type}</td>
                                                <td className='text-center'> <button
                                                    className="btn btn-primary text-sm rounded-5"
                                                    onClick={() => console.log('View button clicked!')}
                                                ><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-download" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" /><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" /></svg>
                                                </button></td>
                                                <td className='text-center'>{new Date(item.uploadat).toLocaleDateString()}</td>
                                                <td className='text-center'><button className="btn btn-primary text-sm" data-bs-toggle="modal" data-bs-target="#ViewdescriptionModual">{item.description}</button></td>
                                                <td className='text-center'> <div className="dropdown">
                                                    <button
                                                        className="btn btn-primary-600 not-active px-12 py-6 text-sm dropdown-toggle toggle-icon"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        {selectedValue} {/* Display selected dropdown value */}
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <a
                                                                className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                                                href="#"
                                                                onClick={() => handleSelect("Active")}
                                                            >
                                                                Active
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                                                href="#"
                                                                onClick={() => handleSelect("Inactive")}
                                                            >
                                                                Inactive
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DescriptionPopup/>
        </>
    )
}
export default DocumentationContainer