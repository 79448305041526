import { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

const TopTabs = () => {
    const { projectId } = useParams();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('skill');

    useEffect(() => {
        // Check the current path and set the active tab accordingly
        if (location.pathname.includes('/skill')) {
            setActiveTab('skill');
        } else if (location.pathname.includes('/module')) {
            setActiveTab('module');
        }
        else if (location.pathname.includes('/task')) {
            setActiveTab('task');
        }
        else if (location.pathname.includes('/individual')) {
            setActiveTab('individual');
        }
        else if (location.pathname.includes('/client-delivery')) {
            setActiveTab('client-delivery');
        }
        else if (location.pathname.includes('/production')) {
            setActiveTab('production');
        }
        else if (location.pathname.includes('/profit-and-loss')) {
            setActiveTab('profit-and-loss');
        }
        else if (location.pathname.includes('/feedbacklog')) {
            setActiveTab('Feedback Log');
        }
        else if (location.pathname.includes('/documentation')) {
            setActiveTab('documentation');
        }
    }, [location.pathname]);

    const TabsConst = [
        { name: "Skill", url: `/projects/view/skill/${projectId}` },
        { name: "Task", url: `/projects/view/task/${projectId}` },
        { name: "Individual", url: `/projects/view/individual/${projectId}` },
        { name: "Client-Delivery", url: `/projects/view/client-delivery/${projectId}` },
        { name: "Module", url: `/projects/view/module/${projectId}` },
        // { name: "Vendor Production", url: `/projects/view/vendor-production/${projectId}` },
        { name: "Production", url: `/projects/view/production/${projectId}` },
        // { name: "Project Documentation", url: `/projects/view/project-documentation/${projectId}` },
        // { name: "Vendor Payment", url: `/projects/view/vendor-payment/${projectId}` },
        { name: "Profit-and-Loss", url: `/projects/view/profit-and-loss/${projectId}` },
        { name: "Feedback Log", url: `/projects/view/feedbacklog/${projectId}` },
        { name: "Documentation", url: `/projects/view/documentation/${projectId}` }
    ];

    return (
        <div className="tabsAllPages">
            <nav className="tabs">
                <ul className="-primary nav my-16">
                    {TabsConst.map((tab) => (
                        <li key={tab.name} className={activeTab === tab.name.toLowerCase() ? 'active' : ''}>
                            <Link
                                to={tab.url}                                
                            >
                                {tab.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default TopTabs;
