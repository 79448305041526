import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import config from "src/config/index";
import { toast } from 'react-toastify';
import { postRequest } from "src/utils/RequestUtil";
import { getUserList } from "src/api/UserApi";
import AssignUser from "./AssignUser";

const {data:{data:users}} = await getUserList();

const AssignTask = ({ getClientList,role,handleLoadPermissions }) => {
 
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const { usersForProjectAssign, currentSelectedProjectId, projectCode, projectSub } = useSelector(state => state.ClientReducer, shallowEqual);
  const [userList,setUserList] = useState([]);
  useEffect(() => { 
    const updatedUserList = users.map(user => ({
      ...user,
      isUserAssigned: usersForProjectAssign.includes(user._id) // Assuming user._id is the identifier
    })); 
    updatedUserList.sort((a, b) => {
      if (a.isUserAssigned === b.isUserAssigned) return 0;
      return a.isUserAssigned ? -1 : 1;
    });
    setUserList(updatedUserList);
  }, [
    usersForProjectAssign,
    users
  ]);

  const handleUserSearch = useCallback(
    debounce(async(searchKey, getUserList) => {
      const whereClauseKeysPaths = ['firstName', 'lastName', 'email'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      };
      const users = await getUserList(whereWithOrCondition);
      setUserList(users.data.data);
    }, 300),
    []
  );



  const [usersForProjectAssignState, setUsersForProjectAssignState] = useState(usersForProjectAssign);

  const handleUserToggle = async (userId) => {
    setUsersForProjectAssignState((prevState) => {
      if (prevState.includes(userId)) {
        return prevState.filter((id) => id !== userId);
      } else {
        return [...prevState, userId];
      }
    });
  };

  const handleSubmit = async () => {
    await postRequest({
      url: `${config.baseUrl}project/saveAssignees`,
      data: {
        userIds: usersForProjectAssignState,
        projectId: currentSelectedProjectId,
        role: role,
        by: userId
      }
    });
    await getClientList();
    toast("User Assigned Successfully");
  };

  useEffect(() => {
    setUsersForProjectAssignState(usersForProjectAssign);
  }, [usersForProjectAssign]);

  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  return (
    <div className="offcanvas offcanvas-end filterSecPopup mdlw700px" id="assignTaskPopup" aria-labelledby="assignTaskPopupSec">
      <div className="offcanvas-header flex-column">
        <h5 className="border-0 text-xl pb-10 w-100" id="filteraddClientSec">
          Assign Task To Users for project: <span className="AssnSpn">{`${projectCode}-(${projectSub})`}</span>
        </h5>
        <div className="mangeSearchSec w-100 mb-0">
          <input
            type="text"
            className="managecolsSearch"
            placeholder="Search users by name or email"
            onChange={(e) => handleUserSearch(e.target.value, getUserList)}
          />
          <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.3215 14.9883L12.0198 10.6866C12.9629 9.55576 13.5321 8.10274 13.5321 6.51846C13.5321 2.92412 10.608 0 7.01362 0C3.41926 0 0.495117 2.92412 0.495117 6.51846C0.495117 10.1128 3.41926 13.0369 7.01362 13.0369C8.59791 13.0369 10.0509 12.4677 11.1818 11.5246L15.4835 15.8264C15.5992 15.9421 15.7509 16 15.9025 16C16.054 16 16.2057 15.9421 16.3215 15.8264C16.553 15.5948 16.553 15.2197 16.3215 14.9883ZM1.6803 6.51846C1.6803 3.57774 4.07259 1.18517 7.01362 1.18517C9.95465 1.18517 12.3469 3.57774 12.3469 6.51846C12.3469 9.45917 9.95465 11.8517 7.01362 11.8517C4.07259 11.8517 1.6803 9.45917 1.6803 6.51846Z"
              fill="#323338"
            />
          </svg>
        </div>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="assignPopoHeight">
          {userList && userList.map((item, index) => (<AssignUser 
          index = {index} 
          key={index} 
          item={item} 
          handleUserToggle={handleUserToggle} 
          usersForProjectAssignState={usersForProjectAssignState}
          handleLoadPermissions={handleLoadPermissions}
          />))}
        </div>
      </div>
      <div className="offcanvas-footer px-16">
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default AssignTask;
