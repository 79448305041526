import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getMTDDeliveryAPI, updateCostUnit } from "src/api/ProductionApi";
import MonthPicker from "src/components/MonthPicker";
import SearchInput from "src/components/SearchInput";
import MDTRow from "./MDTRow";

const MTDContainer=()=>{

    const [tableData,setTableDate] = useState([]);
    const [searchedMTD,setSearchedMTD] = useState([]);
    const {selectedMonth,selectedYear} = useSelector(state=>state.ProductionReducer,shallowEqual);
    const [where, setWhere] = useState({
      $expr: {
      $and: [
        { $eq: [{ $year: "$date" },selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] } 
      ]
    }}
  );
    const handleChangeDate = (date)=>{
        const year = date.getFullYear();  // Example year
        const month = date.getMonth()+1;  // Since month is 0-indexed in JavaScript, add 1
        setWhere({
          ...where,
          $expr: {
            $and: [
              { $eq: [{ $year: "$date" },year] },
              { $eq: [{ $month: "$date" }, month] } 
            ]
          }
        })
      }
 
  const search = useCallback(async()=>{
    const MDTDeliveryData = await getMTDDeliveryAPI({where})
    setSearchedMTD(MDTDeliveryData.data.data)
    setTableDate(MDTDeliveryData.data.data)
  },[where])
  useEffect(() => {
    search()
  },[where])

  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value.trim().toLowerCase(); // Normalize and trim search input
    if (searchKey.length === 0) {
      // If search key is empty, reset to full table data
      setSearchedMTD(tableData);
    } else {
      // Filter based on the productionSlug field
      const filteredData = tableData.filter((production) => {
        return production?.productionSlug?.toLowerCase().includes(searchKey);
      });
      setSearchedMTD(filteredData); // Set the filtered results
    }
  };
let totalDuration = 0;
let totalCostUnit = 0;
let totalClientCost = 0;

    return (
        <>
         <div>
      <div className="col-lg-12">
        <div className="card"> 
          <div className="card-header">
          <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> MTD Delivery</h2> 
          <ul className="d-flex align-items-center gap-2"><li className="fw-medium"><a href="index-2.html" className="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Home</a></li><li>-</li><li className="fw-medium"> MTD Delivery </li></ul>
          <div className="d-flex align-items-center gap-4 mt-16">
              {/* Search start */}
              <SearchInput handleSearchInputChange={handleSearchInputChange}/>
                {/* Search End */}
                <MonthPicker handleChangeDate={handleChangeDate} />
          </div>
          </div>
          <div className="card-body">
          <h5 className="card-title mb-16">Client Delivery List</h5>
          <div className="table-responsive">
      <table className="table basic-border-table mb-0 table-hover">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Project</th>
            <th scope="col">Manager</th>
            <th scope="col" className="text-center">Count</th>
            <th scope="col" className="text-center">Cost Per Unit (₹)</th>
            <th scope="col" className="text-center">Client Total (₹)</th>
          </tr>
        </thead>
        <tbody>
          {searchedMTD.map((row, index) => {
            totalDuration = totalDuration + row.totalDurationSum;
            let unitCost = 0;
            // Check which unitCost to use based on the condition
            if (row._id.subCategoryId === null && row._id.categoryId === null) { 
              unitCost = row.projectDetails.unitCost;
          } else if (row._id.subCategoryId === null) { 
            unitCost = row.categoryDetails.unitCost; 
          } else { 
            unitCost = row?.subCategoryDetails?.unitCost; 
          }       
          // Multiply the totalDuration with the appropriate unitCost
          const totalCost = row.totalDurationSum * unitCost;
          totalClientCost = totalClientCost + totalCost;
          totalCostUnit = totalCostUnit + unitCost
            return(
            <MDTRow row={row} index={index} search={search}/>
          )})}
        </tbody>
        <tfoot>
          <tr>
            <th scope="col" colSpan={3}></th>            
            <th scope="col" className="text-center">{totalDuration.toFixed(2)} </th>
            <th scope="col" className="text-center">{totalCostUnit.toFixed(2)} (₹)</th>
            <th scope="col" className="text-center">{totalClientCost.toFixed(2)} (₹)</th>
          </tr>
        </tfoot>
      </table>
    </div>
          </div>
        </div> 
      </div>
    </div>
        </>
    )
}
export default MTDContainer