import React, { useState, useEffect } from 'react';
import TopTabs from "../TopTabs"
import IndividualFilter from "./FIlter"
import ManageIndividualColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { individualProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { shallowEqual, useSelector } from 'react-redux';
import { generateColor, getContrastYIQ } from 'src/utils/utils';


const IndividualUserContainer = () => {

  const { projectId } = useParams();
  const [individual, setIndividual] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState('');
  const {currentRecord} = useSelector((state) => state.ProjectReducer,shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode)?`${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}`:''



  const getProjectIndividualData = async () => {
    let body = {
      pageLength: 100
    };

    try {
      const resp = await individualProjectSearchAPI({ projectId, body });
      console.log('response from project Task', resp?.data?.data);

      const skillsData = resp?.data?.data || [];

      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item?.fullName?.toLowerCase().includes(search.toLowerCase())
      );

      // Calculate total duration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.approvedDurations || 0;
        acc.totalCost += item.cost || 0;
        return acc;
      }, { totalDuration: 0, totalCost: 0 });


      // Set the filtered skills and totals in state
      setIndividual(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);

    } catch (error) {
      console.log('error from project Task', error);
    }
  };

  useEffect(() => {
    getProjectIndividualData();
  }, [search]);

  const handleDateRangeChange = (selectedDates) => {
   
  }

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Individual </li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                 
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {projectCode}
                </h6>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      {/* <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th> */}
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Name </th>
                      <th className="text-center">Task Count </th>
                      <th className="text-center">No. of Units </th>
                      {/* <th className="text-center">Cost</th> */}
                    </tr>
                  </thead>
                  {individual?.map((item, index) => (
                    <tbody>
                      <tr className="dnd-moved">
                        {/* <td>
                          <input className="form-check-input" type="checkbox" />
                        </td> */}
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                              draggable="false"
                              data-bs-toggle="collapse"
                              data-bs-target={`#demo1${index}`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                              </svg>
                            </button>
                            <div className="d-flex align-items-center gap-2">
                            <span
                                       className="img imgsSet me-0"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       data-bs-custom-class="tooltip-dark"
                                       data-bs-title={`${item?.fullName}`}
                                     >
                                       {item?.userId?.profilePicture ? (
                                         <img
                                           src={item?.userId?.profilePicture}
                                           className="img-fluid"
                                           alt=""
                                         />
                                       ) : (
                                         <span
                                           className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                           style={{
                                             backgroundColor: generateColor(`${item?.fullName}`),
                                             color: getContrastYIQ(generateColor(`${item?.fullName}`)),
                                           }}
                                         >
                                           {`${item?.fullName.charAt(0)}`}
                                         </span>
                                       )}
                                     </span>
                              {/* <span className="userimg" /> */}
                              <div className="flex-grow-1">
                                <h6 className="text-md mb-0 fw-medium text-primary-600">
                                  {item?.fullName}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">{item?.taskDetails.length}</td>
                        <td>
                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#02BC7D"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              />
                            </svg>{" "}
                            {item?.approvedDurations}
                          </div>
                        </td>
                        {/* <td className="text-center">
                          <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                            ₹ {item?.cost?.toFixed(2)}
                          </span>
                        </td> */}
                      </tr>
                      <tr className="bgliggry">
                        <td colSpan={1} className="hiddenRow border-0 p-0 bgliggry" />
                        <td colSpan={4} className="hiddenRow border-0 p-0 bgliggry">
                          <div
                            id={`demo1${index}`}
                            className="accordion-collapse collapse uldropdn"
                          >
                            {item?.taskDetails?.map((item) => (
                              <ul>
                                <li></li>
                                <li>{item?.taskName}</li>
                                <li className="text-center">
                                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-check-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="#D0D4E4"
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                      ></path>
                                    </svg>{" "}
                                    {item?.approvedDurations}
                                  </div>
                                </li>
                                {/* <li className="text-center">₹{item?.cost?.toFixed(2)}</li> */}
                              </ul>
                            ))}


                          </div>
                        </td>
                      </tr>



                    </tbody>
                  ))}

                  <tfoot>
                    <tr>
                      <td colSpan={1} />
                      <td colSpan={2} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration}</span> Total Units
                      </td>
                      {/* <td className="text-center">
                        <span className="d-block fw-bold">₹{totalcost?.toFixed(2)}</span> Total Cost
                      </td> */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IndividualFilter />
      <ManageIndividualColum />
    </>
  )
}
export default IndividualUserContainer