import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftNavBar from '../layout-container/LeftNavBar';
import HeaderNav from '../layout-container/HeaderNav';
import ProductionContainer from '../production-container/ProductionContainer';
import Login from '../login-container/Login';
import ClientContainer from '../client-container/ClientContainer';
import ProjectContainer from '../project-container/ProjectContainer';
import UserContainer from '../user-container/UserContainer';
import EmployeeDataContainer from '../employee-container/Data/EmployeeDataContainer';
import EmployeeProductionContainer from '../employee-container/Production/EmployeeProductionContainer';
import EmployeeProjectContainer from '../employee-container/Project/EmployeeProjectContainer';
import EmployeeSkillContainer from '../employee-container/Skill/EmployeeSkillContainer';
import ModuleContainer from '../project-container/Module/ModuleContainer';
import FeedBackLog from 'src/components/FeedBackLog';
import MTDContainer from '../MTD-container/MDTContainer';
import DeliveryDeviationContainer from '../delivery-daviation-container/DeliveryDeviationContainer';
import PermissionContainer from '../permission-container/PermissionContainer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchUserScopesAction } from '../home-container/actions/authAction';
import ViewPerformance from 'src/components/ViewPerformance';
import TaskPendingContainer from '../pending-container/TaskPendingContainer';
import ProductionPendingContainer from '../pending-container/ProductionPendingContainer';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import Documentation from 'src/components/Documentation';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import FeedbackLMS from 'src/components/FeedbackLMS';
import FeedbackLog2 from 'src/components/FeedbackLog2';
import MyAudit from 'src/components/MyAudit';
import UCLTopScorersChart from 'src/components/UCLTopScorersChart';
import ProductionListContainer from '../pending-container/ProductionContainer';
import AuditReport from 'src/components/AuditReport';
// import Permission from 'src/components/Permission';

// import Home from '../home-container/Home';
function MainContainer() {
  const dispatch = useDispatch();
  const userScopes = useSelector((state) => state.auth.user.userScopes, shallowEqual);
  if(userScopes && userScopes.length === 0){
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    dispatch(fetchUserScopesAction({ userId:user?._id,roleId:user?.roleDetails?._id }));
    setDefaultHeaders({
      Authorization: token,
     });
  }
  return (
    <>
      <LeftNavBar />
      <main className="dashboard-main">
        <HeaderNav />
        <div className="dashboard-main-body pt-0">
          <Routes>

            <Route index element={<ProductionContainer />} />
            <Route path="productions" element={<ProductionContainer />} />
            <Route path="projects/*" element={<ProjectContainer />} />
            <Route path="clients" element={<ClientContainer />} />
            <Route path="employee-data" element={<EmployeeDataContainer />} />
            <Route path="view-production/:userId" element={<EmployeeProductionContainer />} />
            <Route path="view-project" element={<EmployeeProjectContainer />} />
            <Route path="skill" element={<EmployeeSkillContainer />} />
            <Route path="module" element={<ModuleContainer />} />
            <Route path="feedback-log" element={<FeedBackLog />} />
            <Route path="mtd-delivery" element={<MTDContainer />} />
            <Route path="delivery-deviation" element={<DeliveryDeviationContainer />} />
            <Route path="permission" element={<PermissionContainer />} />
            <Route path="performance" element={<ViewPerformance />} />
            <Route path="pending-task-list" element={<TaskPendingContainer />} />
            <Route path="pending-production-list" element={<ProductionPendingContainer />} />
            <Route path="production-list" element={<ProductionListContainer />} />
              <Route path="documentation" element={<Documentation />} />  
              <Route path="feedback-lms" element={<FeedbackLMS />} />  
              <Route path="feedbacklog" element={<FeedbackLog2 />} />  
              <Route path="myaudit" element={<MyAudit />} />  
              <Route path="scorerschart" element={<UCLTopScorersChart />} />  
              <Route path="auditreport" element={<AuditReport />} />  

          </Routes>
        </div>
      </main>

      {/**/}
    </>
  );
}

export default MainContainer;
