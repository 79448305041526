import React, { useState, useEffect } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import { projectCostAPI } from 'src/api/ProjectApi';
import DatePickerComponent from 'src/components/DatePickerComponent';
import SearchInput from 'src/components/SearchInput';



const EmployeeProjectContainer = () => {

  const [viewproject, setViewProject] = useState([])
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [targetSums, setTargetSums] = useState({
    'Daily Target': 0,
    'Open': 0,
    'Bound': 0,
  });
  const [unitSums, setUnitSums] = useState({
    'Per Day': 0,
    'min': 0,
  });
  const [where,setWhere] = useState({});


  const getViewProjectSearch = async () => {
    let body = {
      pageLength: 100,
      where
    }

    try {
      const resp = await projectCostAPI(body);
      // const viewProjectData = resp?.data?.data?.data || [];
      const viewProjectData = resp.data.data.data.filter(user => {
        const userDate = new Date(user.ts);
        const isWithinDateRange = (!startDate || userDate >= new Date(startDate)) && (!endDate || userDate <= new Date(endDate));
        const matchesSearch = `${user.clientDetails?.ClientProjectCode}`.toLowerCase().includes(search.toLowerCase());
        return isWithinDateRange && matchesSearch;
      });

      let targetSums = {
        'Daily Target': 0,
        'Open': 0,
        'Bound': 0
      };

      viewProjectData.forEach(project => {
        project.taskDetails.forEach(task => {
          if (targetSums.hasOwnProperty(task.item)) {
            targetSums[task.item] += task.target;
          } else {
            targetSums[task.item] = task.target;
          }
        });
      });

      let unitSums = {
        'Per Day': 0,
        'min': 0
      };

      viewProjectData.forEach(project => {
        project.taskDetails.forEach(task => {
          if (unitSums.hasOwnProperty(task.unit)) {
            unitSums[task.unit] += task.target;
          } else {
            unitSums[task.unit] = task.target;
          }
        });
      });
      setTargetSums(targetSums);
      setViewProject(viewProjectData);
      setUnitSums(unitSums)
    } catch (error) {
      console.log('error from the view project', error);
    }
  }


  useEffect(() => {
    getViewProjectSearch()
  }, [search, startDate, endDate])


  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) { 
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); 
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });  
      setWhere({...where, ts: { $gte: startDate, $lte: endDate } })
    }
  }


  const totalTarget = targetSums['Daily Target'] + targetSums['Open'] + targetSums['Bound'];
  const dailyTargetPercent = totalTarget > 0 ? (targetSums['Daily Target'] / totalTarget) * 100 : 0;
  const openPercent = totalTarget > 0 ? (targetSums['Open'] / totalTarget) * 100 : 0;
  const boundPercent = totalTarget > 0 ? (targetSums['Bound'] / totalTarget) * 100 : 0;


  const totalTarget1 = unitSums['Per Day'] + unitSums['min']
  const dailyTargetPercent1 = totalTarget1 > 0 ? (unitSums['Per Day'] / totalTarget1) * 100 : 0;
  const openPercent1 = totalTarget1 > 0 ? (unitSums['min'] / totalTarget1) * 100 : 0;


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width={30}
                    height={30}
                  >
                    <g data-name="20-Arrow Left">
                      <path
                        fill="#0073ea"
                        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                      />
                      <path
                        fill="#0073ea"
                        d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                      />
                    </g>
                  </svg>
                </button>{" "}
                View Project
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="index-2.html"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium"> View Project </li>
              </ul>
              {/* <EmployeeTabs /> */}
              <div className="d-flex gap-2 align-items-center py-16"> 
                <SearchInput /> 
                <DatePickerComponent onDateRangeChange={handleDateRangeChange} /> 
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  Project
                </h6>
              </div>
              <div
                className="table-responsive table-container"
                id="table-container"
              >
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Project Code </th>
                      <th className="text-center">Task Count </th>
                      <th className="text-center">Number </th>
                      <th className="text-center">Item</th>
                      <th className="text-center">Unit </th>
                      <th className="text-center">Cost</th>
                    </tr>
                  </thead>
                  {viewproject?.map((item, index) => (
                    <tbody>
                      <tr className="dnd-moved">
                        <td>
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                              draggable="false"
                              data-bs-toggle="collapse"
                              data-bs-target={`#demo_${item._id}`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                              </svg>
                            </button>
                            <div className="d-flex align-items-center gap-2 fw-medium text-primary-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-stack"
                                viewBox="0 0 16 16"
                              >
                                <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
                                <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
                              </svg>{" "}
                              {item?.clientDetails?.ClientProjectCode}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">{item?.taskDetails?.length}</td>
                        <td>
                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#02BC7D"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              />
                            </svg>{" "}
                            {item?.approvedDurations}
                          </div>
                        </td>
                        <td>
                          <div className="progress" style={{ height: 30 }}>
                            <div
                              className="progress-bar w-50 bg-green"
                              role="progressbar"
                              aria-valuenow={dailyTargetPercent}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                            <div
                              className="progress-bar w-50 bg-orange"
                              role="progressbar"
                              aria-valuenow={openPercent}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                            <div
                              className="progress-bar w-50 bg-blue"
                              role="progressbar"
                              aria-valuenow={boundPercent}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="progress opacity-5" style={{ height: 30 }}>
                            <div
                              className="progress-bar w-50 bg-green"
                              role="progressbar"
                              aria-valuenow={dailyTargetPercent1}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                            <div
                              className="progress-bar w-50 bg-lightblue"
                              role="progressbar"
                              aria-valuenow={openPercent1}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />

                          </div>
                        </td>
                        <td className="text-center">
                          <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                            ₹ {item?.cost}
                          </span>
                        </td>
                      </tr>

                      {item?.taskDetails?.map((item,indexSub) => (
                        <tr className="bgliggry">
                          <td colSpan={2} className="hiddenRow border-0 p-0 bgliggry" />
                          <td colSpan={6} className="hiddenRow border-0 p-0 bgliggry">
                            <div
                              id={`demo_${item._id}`}
                              className="accordion-collapse collapse uldropdn"
                            >
                              <ul>
                                <li />
                                <li>{item?.taskName}</li>
                                <li className="text-center">
                                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-check-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="#D0D4E4"
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                      ></path>
                                    </svg>{" "}
                                    {item?.target}
                                  </div>
                                </li>
                                <li className="text-center">
                                  <button
                                    type="button"
                                    className="btn radius-8 px-14 py-6 text-sm"
                                    style={{
                                      backgroundColor:
                                        item?.item === 'Daily Target'
                                          ? 'lightgreen'
                                          : item?.item === 'Bound'
                                            ? 'blue'
                                            : item?.item === 'Open'
                                              ? 'orange'
                                              : '',
                                      color: 'white',
                                    }}
                                  >
                                    {item?.item}
                                  </button>
                                </li>

                                <li className="text-center">
                                  <button
                                    type="button"
                                    className="btn radius-8 px-14 py-6 text-sm"
                                    style={{
                                      backgroundColor:
                                        item?.unit === 'Per Day'
                                          ? 'lightgreen'
                                          : item?.unit === 'min'
                                            ? 'lightblue'
                                            : '',
                                      color: 'white',
                                      borderColor:
                                        item?.unit === 'Per Day'
                                          ? 'green'
                                          : item?.unit === 'min'
                                            ? 'blue'
                                            : '',
                                    }}
                                  >
                                    {item?.unit}
                                  </button>
                                </li>
                                <li className="text-center">₹{item?.cost}</li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterPopup />
      <ManageColumn />
    </>
  )
}

export default EmployeeProjectContainer