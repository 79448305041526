import { shallowEqual, useSelector } from "react-redux";
import { generateMonthData } from "src/utils/CommonUtils"
import { getDateFromDate, getDayFromDate } from "src/utils/utils"

const AttendanceCalendar=(props)=>{
  const {selectedMonth, selectedYear,search} = useSelector((state)=>state.ProductionReducer,shallowEqual);
  // Month value should be from 0 to 11
  const year = selectedYear;
  const month = (selectedMonth-1); // August (0-indexed, so 7 represents August)
  const dateWiseProductions = search.data.filter(item => item.date).map(item => new Date(item.date).toISOString().split('T')[0]);
  const monthDays = generateMonthData(year,month,dateWiseProductions)

   return (
    <>
    <div className="row mb-16 mt-40">
      {
        monthDays.map((item, monthIndex) => {
          return (
            <>
                <div className="col" key={monthIndex}>
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        {
                          item.map((day, weekIndexDate) => {
                            // console.log(, "day")
                            return (
                              <div className="text-muted-foreground" key={`date_${weekIndexDate}`}>{getDateFromDate(day.date)}</div>
                            )
                          })
                        }
                        {/* <div className="text-muted-foreground">1</div>
                        <div className="text-muted-foreground">2</div>
                        <div className="text-muted-foreground">3</div>
                        <div className="text-muted-foreground">4</div>
                        <div className="text-muted-foreground">5</div>
                        <div className="text-muted-foreground">6</div>
                        <div className="text-muted-foreground">7</div> */}
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                      {
                          item.map((day, weekIndexDay) => {
                            // console.log(, "day")
                            return (
                              <div className="text-muted-foreground" key={`day_${weekIndexDay}`}>{getDayFromDate(day.date)}</div>
                            )
                          })
                        }
                        {/* <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">F</div>
                        <div className="text-muted-foreground">S</div>
                        <div className="text-muted-foreground">S</div> */}
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                      {
                          item.map((day, weekIndexAttendance) => {
                            if(day.label === "P")
                            return (
                              <div className="bg-green-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>P</div>
                            )
                            else if(day.label === "W")
                            return (
                              <div className="bg-pink-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>W</div>
                            )
                            else if(day.label === "SL")
                            return (
                              <div className="bg-yellow-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>SL</div>
                            )
                            else if(day.label === "A")
                            return (
                              <div className="bg-red-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>A</div>
                            )
                            else
                            return (
                              <div className="bg-zinc-300 text-zinc-500 py-1 rounded"   key={`att_${weekIndexAttendance}`}/>
                            )
                          })
                        }
                        {/* <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-pink-500 text-white py-1 rounded">H</div>
                        <div className="bg-yellow-500 text-white py-1 rounded">SL</div>
                        <div className="bg-red-500 text-white py-1 rounded">A</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" /> */}
                      </div>
                    </div>
                  </div>
            </>
          )
        })
      }
                  {/* <div className="col">
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        <div className="text-muted-foreground">1</div>
                        <div className="text-muted-foreground">2</div>
                        <div className="text-muted-foreground">3</div>
                        <div className="text-muted-foreground">4</div>
                        <div className="text-muted-foreground">5</div>
                        <div className="text-muted-foreground">6</div>
                        <div className="text-muted-foreground">7</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">F</div>
                        <div className="text-muted-foreground">S</div>
                        <div className="text-muted-foreground">S</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-pink-500 text-white py-1 rounded">H</div>
                        <div className="bg-yellow-500 text-white py-1 rounded">SL</div>
                        <div className="bg-red-500 text-white py-1 rounded">A</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        <div className="text-muted-foreground">8</div>
                        <div className="text-muted-foreground">9</div>
                        <div className="text-muted-foreground">10</div>
                        <div className="text-muted-foreground">11</div>
                        <div className="text-muted-foreground">12</div>
                        <div className="text-muted-foreground">13</div>
                        <div className="text-muted-foreground">14</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">F</div>
                        <div className="text-muted-foreground">S</div>
                        <div className="text-muted-foreground">S</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-pink-500 text-white py-1 rounded">H</div>
                        <div className="bg-yellow-500 text-white py-1 rounded">SL</div>
                        <div className="bg-blue-500 text-white py-1 rounded">CL</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        <div className="text-muted-foreground">15</div>
                        <div className="text-muted-foreground">16</div>
                        <div className="text-muted-foreground">17</div>
                        <div className="text-muted-foreground">18</div>
                        <div className="text-muted-foreground">19</div>
                        <div className="text-muted-foreground">20</div>
                        <div className="text-muted-foreground">21</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">F</div>
                        <div className="text-muted-foreground">S</div>
                        <div className="text-muted-foreground">S</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-pink-500 text-white py-1 rounded">H</div>
                        <div className="bg-yellow-500 text-white py-1 rounded">SL</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        <div className="text-muted-foreground">22</div>
                        <div className="text-muted-foreground">23</div>
                        <div className="text-muted-foreground">24</div>
                        <div className="text-muted-foreground">25</div>
                        <div className="text-muted-foreground">26</div>
                        <div className="text-muted-foreground">27</div>
                        <div className="text-muted-foreground">28</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">F</div>
                        <div className="text-muted-foreground">S</div>
                        <div className="text-muted-foreground">S</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-pink-500 text-white py-1 rounded">H</div>
                        <div className="bg-yellow-500 text-white py-1 rounded">SL</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-green-500 text-white py-1 rounded">P</div>
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 py-1 rounded" />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="p-4 bg-card text-card-foreground rounded-lg">
                      <div className="grid grid-cols-7 gap-1 text-center text-sm font-medium">
                        <div className="text-muted-foreground">29</div>
                        <div className="text-muted-foreground">30</div>
                        <div className="text-muted-foreground">31</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="text-muted-foreground">M</div>
                        <div className="text-muted-foreground">T</div>
                        <div className="text-muted-foreground">W</div>
                      </div>
                      <div className="grid grid-cols-7 gap-1 mt-2 text-center text-sm font-medium">
                        <div className="bg-zinc-300 text-zinc-500 text-white py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 text-white py-1 rounded" />
                        <div className="bg-zinc-300 text-zinc-500 text-white py-1 rounded" />
                      </div>
                    </div>
                  </div> */}
                </div>
    </>
   )
}
export default AttendanceCalendar