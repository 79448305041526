import { useCallback, useEffect, useMemo, useState } from "react"
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { includeStamp } from "src/utils/StampUtils";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FormikTree from "src/components/formik/FormikTree";
import { getAllProjectPermissionsAPI, userProjectPermissionsCreateAPI } from "src/api/ProjectApi";
import { permissionsToTreeStructure } from "src/utils/CommonUtils";
import { ProjectPermissionsYupSchema } from "./ProjectPropTypes";

const Render=(props)=>{ 
    const {
        values, 
        scopeTree,
        projectCode,
        projectSub,
        selectedUser
    } = props;

    return(
     <>
        <Form autoComplete="disabled"> 
        <div className="card-body">
            <h6 className="mb-4 text-xl">{`${projectCode}(${projectSub}) `} Permission To {selectedUser.firstName} {selectedUser.lastName} ({selectedUser.employeeID})</h6>                               
            <div className="form-group col mt-24">
            <FormikTree
                nodes={scopeTree}
                name="permissions"
                checked={values.permissions}
            />
        </div>
            <div className="form-group text-end gap-3 d-flex justify-content-end mt-24">
                <button type="submit" className="form-wizard-next-btn btn btn-primary-600 px-32">Assign Permissions</button>
                <button type="button" aria-label="Close" data-bs-dismiss="modal"   className="form-wizard-next-btn btn btn-primary-600 px-32">Close</button> 
            </div>
        </div>
        </Form>
     </>
    ) 
  }
  
  Render.propTypes = {
    scopeTree: PropTypes.array.isRequired,
    values: PropTypes.shape({
        permissions: PropTypes.array,
        permissions: PropTypes.bool,
    }),
    resetForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    forceRefreshSearchContainer: PropTypes.object.isRequired,
    onClear: PropTypes.func.isRequired,
 };
  let setSubmitting = () => { };
const ProjectPermissionPopup=({selectedUser,userSelectedProjectPermission})=>{ 
     
    const dispatch = useDispatch();
    const [scopes,setScopes] = useState([]);
    const currentRecord = {permissions:userSelectedProjectPermission};
    const {currentSelectedProjectId,projectCode,projectSub}  = useSelector((state) => state.ClientReducer, shallowEqual); 
    const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);

    const getPermissionMaster = async()=>{
        const response = await getAllProjectPermissionsAPI();
        if(response && response.data){
            setScopes(response.data.data.data[0].scopes);
        }
    }

    useEffect(() => {
        getPermissionMaster();
    },[])

    const scopeTree = useMemo(() => permissionsToTreeStructure(scopes), [
        scopes,
    ]);
    console.log('scopeTree',scopeTree);
    const onSubmit = useCallback(async(
        values,
        formikUtils,
    ) => {
        ({ setSubmitting } = formikUtils);     
         includeStamp(values);
         values.projectId = currentSelectedProjectId;
         values.userId = selectedUser._id;
         values.by = userId; 
        // dispatch(rolePermissionsUpsertAction(values)); 
        await userProjectPermissionsCreateAPI(values);
        toast.success('Project Permission Updated successfully!');
    }, [
        dispatch,
        selectedUser._id,
        currentSelectedProjectId,
    ]);
   
     const onClear = useCallback(() => {
        // dispatch(rolePermissionsResetAction());
    }, [
        dispatch,
    ]);

   return (
    <>
    
      <div className="modal fade cusmodl" id="roulsXXModual" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content"> 
                    <div className="modal-body text-center">  
                                <Formik
                                   onSubmit={onSubmit} 
                                   onClear={onClear}
                                   enableReinitialize
                                   initialValues={currentRecord}
                                   validationSchema={ProjectPermissionsYupSchema}
                                >
                                    {(formikProps) => (
                                        <Render 
                                            scopeTree={scopeTree}   
                                            onClear={onClear}
                                            selectedUser={selectedUser}
                                            projectCode={projectCode}
                                            projectSub={projectSub}
                                            {...formikProps}
                                        />
                                    )}
                        </Formik>
                    </div> 
                </div>
            </div>
        </div> 
    </>
   )
}

export default ProjectPermissionPopup