import { useCallback, useEffect, useState } from 'react';
import DateFormat from 'src/utils/DateFormat';
import DropDownSelect from '../main-container/DropDownSelect';
import { useDispatch } from 'react-redux';
import { projectAssigneeLoadAction } from './actions/ClientActions';
import { useAssignPopup } from 'src/hooks/useAssignPopup';
import { generateColor, getContrastYIQ } from 'src/utils/utils';
import AssigneeCircle from './AssigneeCircle';


const ClientList = (props) => { 
  
  const {
    filteredData,
    setClientId,
    handleEditClick,
    handleEditStatusClick,
    handleInputStatusChange,
    handleSaveClick,
    edit,
    edit3,
    handleCancelClick,
    isLoading,
    handleInputChange,
    handleInputChange1,
    handleSaveClick1,
    handleCancelClick1,
    handleEditClick1,
    profitAndLoss,
    pageNo,

  } = props;
  let counter = 1
  const [accessRole, setAccessRole] = useState('admin');
  const { handleOpenAssignPopup } = useAssignPopup();
 
 
const handleKeyDown = (event) => {
  if(event.key === 'Enter') {
    handleSaveClick();
  }
}

  return (
    <>
      <div className="table-responsive table-container" id="table-container">
        <table className="table basic-border-table mb-0 table-hover">
          <thead>
            <tr className="dnd-moved">
              {/* <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th> */}
              <th className="w30px dragablefalse" draggable="false">
                <label className="form-check-label">S.No.</label>
              </th>
              <th>Created Date</th>
              <th>Project Code</th>
              <th>Sub</th>
              <th>Change Status</th>
              <th>P&amp;L</th>
              <th>Expected</th>
              {/* <th>Action</th> */}
              <th>Registered Name</th>
              <th>Assign to</th>
              <th>Comm. Name</th>
              {/* <th>Created Sub Project Approval </th> */}
              <th>Add Sub Project </th>
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              filteredData.map((client, clientIndex) => {
                let totalIndex = pageNo * 50; // Initialize total index based on page number
                return(client.projects.map((project, projectIndex) => {
                  const rowNumber = totalIndex + clientIndex + 1;
                  console.log('rowNumber=>',rowNumber);
                let projectStatusClass = project.ProjectStatus == 'Active' ? 'success-500' : project.ProjectStatus == 'Inactive' ? 'danger-500' : 'neutral-300';
                const profitLoss = profitAndLoss.filter((profit)=> profit.projectId == project._id)[0]
                return (
                  <>
                    <tr className="dnd-moved" key={`${client._id}-${project._id}`}>
                      {/* <td draggable="false">
                                <input className="form-check-input" type="checkbox" />
                              </td> */}
                      <td draggable="false">
                        <label className="form-check-label">{rowNumber}</label>
                      </td>
                      <td><DateFormat dateString={project.ts} /></td>
                      <td className="fw-medium text-primary-600">{client.ClientProjectCode}</td>
                      <td>{project.ProjectSubCode}</td>
                      <td className={`p-0 bg-${projectStatusClass}`} key={project._id}>
                        <div className="btn-group w-100">
                          <div className={`bg-${projectStatusClass} d-flex align-items-center gap-2 rounded-0 px-16 text-white ${project.ProjectStatus == '' ? 'invisible' : ''}`}>
                            <img
                              src="assets/images/left-sidebar/activetable.svg"
                              className="img-fluid"
                            />
                            {project.ProjectStatus}
                          </div>

                          <button
                            type="button"
                            className={`btn btn-${projectStatusClass} dropdown-toggle dropdown-toggle-split rounded-0 border-start`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-reference="parent"
                            onClick={() => handleEditStatusClick(client._id, project._id, project.ProjectStatus)}
                          >
                            {/* <span className="visually-hidden">{project.ProjectStatus}</span> */}
                          </button>

                          <ul className="dropdown-menu">
                            <li className="mb-8">
                              <div className="form-check checked-primary d-flex align-items-center gap-2 radio-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id="radio1"
                                  defaultChecked=""
                                  checked={project.ProjectStatus === 'Active'}
                                  onChange={() => handleInputStatusChange('Active')}
                                />
                                <label
                                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                                  htmlFor="radio1"
                                // onClick={(e) => e.stopPropagation()}
                                >
                                  {" "}
                                  Active{" "}
                                </label>
                              </div>
                            </li>
                            <li className="mt-8">
                              <div className="form-check checked-primary d-flex align-items-center gap-2 radio-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id="radio2"
                                  defaultChecked=""
                                  value='Inactive'
                                  onChange={() => handleInputStatusChange('Inactive')}
                                />
                                <label
                                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                                  htmlFor="radio2"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {" "}
                                  Inactive{" "}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td className="fw-medium text-success-600">{profitLoss?.profitOrLossPercentage||0}%</td>
                      {/* {project.ProjectExpected}% */}
                      <td className="fw-medium text-danger-600">
                     <div className="d-flex align-items-center gap-2">
                     {edit.clientId === client._id && edit.projectId === project._id ? (
                          <>
                            <input
                              type="number"
                              value={edit.ProjectExpected}
                              className="form-control txtRed"
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                            />
                            <button
                              className="btn mx-auto rounded-pill btn-success-600 radius-8 px-6 py-2 d-flex align-items-center gap-2"
                              onClick={handleSaveClick}><i className="mdi mdi-check"></i></button>
                            <button className="btn mx-auto rounded-pill btn-danger-600 radius-8 px-6 py-2 d-flex align-items-center gap-2" onClick={handleCancelClick}><i className="mdi mdi-close"></i></button>
                          </>
                        ) : (
                          <>
                            {project.ProjectExpected} %

                            {accessRole == 'admin' ? (
                              <button className="btn mx-auto rounded-pill btn-info-600 radius-8 px-6 py-6 d-flex align-items-center gap-2" onClick={() => handleEditClick(client._id, project._id, project.ProjectExpected)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-pencil"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
                                </svg>{" "}
                              </button>
                            ) : ''}

                          </>
                        )}
                     </div>
                      </td>

                      <td>{client.ClientRegisteredName}</td>
                      <td>
                        <div className="d-flex align-items-center flex-wrap">
                          {project.participants.employeeDetails && (
                            <>
                              {project.participants.employeeDetails.slice(0, 2).map((user, userIndex) => (<AssigneeCircle key={userIndex} user={user} />))}
                              {project.participants.employeeDetails.length > 2 && (
                                <span className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center">
                                  + {project.participants.employeeDetails.length - 2}
                                </span>
                                // <span className="user-count">+{group.participants.length - 5} more</span>
                              )}
                            </>
                          )} 
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#assignTaskPopup"
                            aria-controls="assignTaskPopup"
                            onClick={() => { handleOpenAssignPopup(project, client,"client") }}
                            className="w-32-px h-32-px rounded-circle border border-primary-600 text-primary-600 ms-8 border-dashed text-secondary-light text-xs d-inline-flex align-items-center justify-content-center"
                          >
                            <iconify-icon icon="ic:baseline-plus" className="" />
                          </button>
                        </div>
                      </td>
                      <td>{client.ClientCommercialName}</td>

                      {/* <DropDownSelect
                        value={project}
                        role={accessRole}
                      /> */}
                      {/*  */}
                      <td className="text-center">
                        <button className="addbtns"  data-bs-toggle="modal" data-bs-target="#taskModual" onClick={() => setClientId(client._id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </>
                )
              }))}
              )}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default ClientList