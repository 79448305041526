import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAssignPopup } from "src/hooks/useAssignPopup";
import { formatDate, time_ago } from "src/utils/CommonUtils";
import { projectOAD_CMD_DetailsAction } from "./actions/ProjectActions";
import { generateColor, getContrastYIQ, getDeviation } from "src/utils/utils";
import PermissionHOC from "src/hoc/PermissionHOC";
import ProjectPermissionHOC from "src/hoc/ProjectPermissionHOC";

const ProjectGridCart=({
  project,
  editClick,
  currentMonthDeliveryCost,
})=>{
const dispatch = useDispatch();
  const { handleOpenAssignPopup,handleProjectAssigneeDetails } = useAssignPopup();
  const totalOAD = project.oad.reduce((total, item) => (item.type=="add")?(total + item.value):(total-item.value), 0);
  const totalCMD = project.cmd.reduce((total, item) => (item.type=="add")?(total + item.value):(total-item.value), 0);
  const Deviation = getDeviation(currentMonthDeliveryCost,totalCMD)
  // Event handler for button
  const handlePlusClick = (type,mode,project) => {
    dispatch(projectOAD_CMD_DetailsAction({data:{type,mode,project}}));     
  };

  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);
  const permissions = project?.permissions[0]?.permissions || [];
console.log('permissions=>',permissions)
    return (
        <>
         <div className="col-xxl-4 col-sm-6">
                <div className="card radius-12 h-100 border shadow-none overflow-hidden prjwhc gbtmb-10">
                  <div className="card-body py-16 px-24">
                    <div className="card-header py-0 px-0 bg-base d-flex align-items-center gap-1 justify-content-between border-0">
                      <h6 className="text-sm mb-0">Project Code: {project.ProjectSubCode}</h6>
                      <span className={`${(project.ProjectStatus==="Active")?'bg-success-focus text-success-main':'bg-danger-focus text-danger-main'} px-24 py-4 radius-4 fw-medium text-sm`}>
                        {(project?.ProjectStatus==="Active")?"Active":"Inactive"}
                      </span>
                    </div>
                    <div className="wst">{project?.RefClientId?.ClientProjectCode}</div>
                    <ul>
                        {project.participants && (
                          <>
                            {project.participants
                              .filter(participant => participant?.role === "Manager") // Filter for only Managers
                              .slice(0, Math.min(5, project.participants.filter(participant => participant?.role === "Manager").length)) // Safely slice the filtered Managers
                              .map((participant, userIndex) => (
                                <li key={`user_${userIndex}`}>
                                  <span className="Projtnm">{participant?.userId?.designationId?.designationName}</span>{" "}
                                  <span className="dots">:</span> {`${participant?.userId?.firstName} ${participant?.userId?.lastName}`}
                                </li>
                              ))
                            }
                          </>
                        )}
                      <li>
                        <span className="Projtnm">Created Date</span>{" "}
                        <span className="dots">:</span> {formatDate(project.ts)}
                      </li> 
                    </ul>
                    {/* <div className="d-flex align-items-center flex-wrap mt-16">
                    {project.participants && (
                    <>
                      {project.participants.slice(0, 5).map((participant, userIndex) => (
                          <img
                          src={`${
                            (participant?.userId?.profilePicture)
                            ? participant?.userId?.profilePicture
                            : "/assets/images/user.png"
                        }`} 
                          alt=""
                          className="w-32-px h-32-px rounded-circle object-fit-cover position-relative"
                        />
                      ))}
                        </>
                      )}
                      {project.participant?.length > 5 && (
                      <span className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center">
                          +{project.participants?.length - 5}
                      </span>
                      )}
                       
                    </div> */}
<div className="d-flex align-items-center justify-content-between mt-16">
  <div className="d-flex align-items-center flex-wrap">

  {project.participants && (
    <>
      {project.participants.slice(0, 2).map((participant, userIndex) => (
                                      <span
                                       className="img imgsSet me-0"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="top"
                                       data-bs-custom-class="tooltip-dark"
                                       data-bs-title={`${participant?.userId?.firstName} ${participant?.userId?.lastName}`}
                                     >
                                       {participant?.userId?.profilePicture ? (
                                         <img
                                           src={participant?.userId?.profilePicture}
                                           className="img-fluid"
                                           alt=""
                                         />
                                       ) : (
                                         <span
                                           className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                           style={{
                                             backgroundColor: generateColor(`${participant?.userId?.firstName}${participant?.userId?.lastName}`),
                                             color: getContrastYIQ(generateColor(`${participant?.userId?.firstName}${participant?.userId?.lastName}`)),
                                           }}
                                         >
                                           {`${participant?.userId?.firstName.charAt(0)}${participant?.userId?.lastName.charAt(0)}`}
                                         </span>
                                       )}
                                     </span>     
      ))}
        </>
      )}
      
      {project.participants?.length > 2 && (
        <>
        <span
        onClick={() => { handleProjectAssigneeDetails(project?.participants) }}
        className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center c-pointer" data-bs-toggle="modal" data-bs-target="#taskModual">
        +{project.participants?.length - 2}
        </span>
        </>
      )}
       <ProjectPermissionHOC scope="project.add_member" scopeMasterList={permissions}>
                <button
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#assignTaskPopup"
                aria-controls="assignTaskPopup" 
                onClick={() => { handleOpenAssignPopup(project, project.RefClientId,"project") }}
                className="w-32-px h-32-px rounded-circle border border-primary-600 text-primary-600 ms-8 border-dashed text-secondary-light text-lg d-inline-flex align-items-center justify-content-center"
              >
                <iconify-icon icon="ic:baseline-plus" className="" />
              </button>
        </ProjectPermissionHOC>
    
  </div>
  <div className="d-flex align-items-center justify-content-between gap-10">
 
    <ProjectPermissionHOC scope="project.create_task" scopeMasterList={permissions}>
    <button
      type="button"
      className="card-edit-button text-primary-600"
      id={project._id}
      onClick={()=>{editClick('edit',project._id)}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="currentColor"
        className="bi bi-pencil"
        viewBox="0 0 16 16"
      >
        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
      </svg>
    </button>
    </ProjectPermissionHOC>
    <ProjectPermissionHOC scope="project.view_report" scopeMasterList={permissions}>
    <button
      type="button"
      onClick={()=>{editClick('view',project._id)}}
      className="btn btn-primary-600 radius-6 px-14 py-6 text-sm"
    >
      View Report
    </button>
    </ProjectPermissionHOC>
  </div>
</div>

                  </div>
                  
                  <div className="d-flex align-items-start justify-content-between mt-12 border-top px-0 py-16 fstxbgncbv">
  <div className="fstx text-center">
  <ProjectPermissionHOC scope="project.oad" scopeMasterList={permissions}>
    <div className="WBtn_fstx">
      <button className="btn_001 PlusBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
        onClick={()=>handlePlusClick('OAD','add',project)}>
        <i className="mdi mdi-plus" />
      </button>
      <div className="oadTxt">
        OAD 
      </div>
      <button className="btn_001 MinBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
        onClick={()=>handlePlusClick('OAD','remove',project)}>
        <i className="mdi mdi-minus" />
      </button>
    </div>
    <span className="prs0new1">₹{totalOAD.toFixed(2)}</span>{" "}
        <button className="p_eye001" data-bs-toggle="modal" data-bs-target="#ViewCmdCostModual"
           onClick={()=>handlePlusClick('OAD','view',project)}>
          <i className="mdi mdi-eye" />
        </button>
  </ProjectPermissionHOC>
  </div>
  <div className="fstx text-center">
  <ProjectPermissionHOC scope="project.cmd" scopeMasterList={permissions}>
    <div className="WBtn_fstx">
      <button className="btn_001 PlusBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
        onClick={()=>handlePlusClick('CMD','add',project)}>
        <i className="mdi mdi-plus" />
      </button>
      <div className="oadTxt">
        CMD 
      </div>
      <button className="btn_001 MinBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
        onClick={()=>handlePlusClick('CMD','remove',project)}>
        <i className="mdi mdi-minus" />
      </button>
    </div>
    <span className="prs0new1">₹{totalCMD.toFixed(2)}</span>{" "}
        <button className="p_eye001" data-bs-toggle="modal" data-bs-target="#ViewCmdCostModual"
         onClick={()=>handlePlusClick('CMD','view',project)}>
          <i className="mdi mdi-eye" />
        </button>
  </ProjectPermissionHOC>
  </div>
  <div className="fstx text-center">
  <ProjectPermissionHOC scope="project.delivered" scopeMasterList={permissions}>
    Delivered <span>₹{currentMonthDeliveryCost}</span>
    </ProjectPermissionHOC>
  </div>
  <div className="fstx text-center">
    {/* overAllDelivery */}
    <ProjectPermissionHOC scope="project.deviation" scopeMasterList={permissions}>
    Deviation <span>{Deviation}%</span>
    </ProjectPermissionHOC>
  </div>
</div>

                </div>
              </div>  
 
        </>
    )
}
export default ProjectGridCart