import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import MonthPicker from './MonthPicker';
import SearchInput from './SearchInput';
import UCLTopScorersChart from './UCLTopScorersChart';
import OrangeCategory from './OrangeCategory';


const milestonesData = [
    {
        title: "Milestone 1",
        duration: "1-10 DAYS",
        progress: 40,
        amount: 4000,
    },
    {
        title: "Milestone 2",
        duration: "11-20 DAYS",
        progress: 60,
        amount: 4000,
    },
    {
        title: "Milestone 3",
        duration: "21-31 DAYS",
        progress: 70,
        amount: 4000,
    },
];

const MyAudit = () => {
    const chartComponentRef = useRef(null);

    const chartOptions = {
        chart: {
            type: 'pie',
            custom: {},
            events: {
                render() {
                    const chart = this;
                    const series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(
                                'Total<br/>' + '<strong>45584</strong>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft;
                    const y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

                    customLabel.attr({ x, y });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `${series.center[2] / 12}px`,
                        lineHeight: `${series.center[2] / 10}px`,
                    });
                },
            },
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 0,
                dataLabels: [
                    {
                        enabled: true,
                        distance: 20,
                        format: '{point.name}',
                    },
                    {
                        enabled: true,
                        distance: -15,
                        format: '{point.percentage:.0f}%',
                        style: {
                            fontSize: '0.9em',
                        },
                    },
                ],
                showInLegend: true,
                point: {
                    events: {
                        click: function () {
                            if (this.name === 'Orange') {
                                handleOrangeClick();
                            } 
                            if (this.name === 'Green') {
                                handleGreenClick();
                            }
                            if (this.name === 'Red') {
                                handleRedClick();
                            }
                            if (this.name === 'No New Allocation') {
                                handleBlueClick();
                            }
                        }, 
                    },
                },
            },
        },
        series: [
            {
                name: 'Registrations',
                colorByPoint: true,
                innerSize: '75%',
                data: [
                    { name: 'No New Allocation', y: 23.9, color: '#2CAFFE', },
                    { name: 'Red', y: 12.6, color: '#EE0B0B', },
                    { name: 'Green', y: 37.0, color: '#02BC7D', },
                    { name: 'Orange', y: 26.4, color: '#FE6A35', },
                ],
            },
        ],
    };


    // second chart Profit and Loss

    // const chartComponentRef = useRef(null);

    const handleOrangeClick = () => {
        // Show the modal when the Orange category is clicked
        const modal = new window.bootstrap.Modal(document.getElementById('categoryModual'));
        modal.show();
    };
    const handleGreenClick = () => {
        // Show the modal when the Green category is clicked
        const modal = new window.bootstrap.Modal(document.getElementById('categoryModual'));
        modal.show();
    };
    const handleRedClick = () => {
        // Show the modal when the Red category is clicked
        const modal = new window.bootstrap.Modal(document.getElementById('categoryModual'));
        modal.show();
    };
    const handleBlueClick = () => {
        // Show the modal when the Blue category is clicked
        const modal = new window.bootstrap.Modal(document.getElementById('categoryModual'));
        modal.show();
    };



    const chartOptionsPL = {
        chart: {
            type: 'pie',
            custom: {},
            events: {
                render() {
                    const chart = this;
                    const series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(
                                'Total<br/>' + '<strong>&#8377; 45584</strong>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft;
                    const y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

                    customLabel.attr({ x, y });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `${series.center[2] / 12}px`,
                        lineHeight: `${series.center[2] / 10}px`,
                    });
                },
            },
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        subtitle: {
            text: '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 0,
                dataLabels: [
                    {
                        enabled: true,
                        distance: 20,
                        format: '{point.name}',
                    },
                    {
                        enabled: true,
                        distance: -15,
                        format: '{point.percentage:.0f}%',
                        style: {
                            fontSize: '0.9em',
                        },
                    },
                ],
                showInLegend: true,
                point: {
                    events: {
                        click: function () {
                            if (this.name === 'Orange') {
                                handleOrangeClick();
                            } 
                            if (this.name === 'Green') {
                                handleGreenClick();
                            }
                            if (this.name === 'Red') {
                                handleRedClick();
                            }
                        }, 
                    },
                },
            },
        },
        series: [
            {
                name: 'Registrations',
                colorByPoint: true,
                innerSize: '75%',
                data: [
                    { name: 'Red', y: 12.6, color: '#EE0B0B' },
                    { name: 'Green', y: 37.0, color: '#02BC7D' },
                    { name: 'Orange', y: 26.4, color: '#FE6A35' }, // This will trigger the modal
                ],
            },
        ],
    };




    // const handleSearchInputChange = (e) => { 
    //   };

    const handleChangeDate = (date) => {

    }
    return (
        <>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                                <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> My Audit</h2>
                                <ul className="d-flex align-items-center gap-2"><li className="fw-medium"><a href="index-2.html" className="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Home</a></li><li>-</li><li className="fw-medium">My Audit </li></ul>
                            </div>
                            <div className="d-flex align-items-center gap-4 mt-16">
                                {/* Search start */}
                                {/* <SearchInput handleSearchInputChange={handleSearchInputChange}/> */}
                                {/* Search End */}
                                <MonthPicker handleChangeDate={handleChangeDate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 pb-16">
                                <h5 className="card-title">Category </h5>
                                <span className="text-sm fw-medium text-secondary-light">Weekly project categorization analysis</span>
                            </div>
                            <div className="col-md-6">
                                <figure className="highcharts-figure">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                        ref={chartComponentRef}
                                    />
                                    <div className="text-center mt-16"><span className="rounded-pill bg-light-100 text-dark fw-bold radius-8 px-20 py-11">Week 1</span></div>
                                </figure>
                            </div>
                            <div className="col-md-6">
                                <figure className="highcharts-figure">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                        ref={chartComponentRef}
                                    />
                                    <div className="text-center mt-16"><span className="rounded-pill bg-light-100 text-dark fw-bold radius-8 px-20 py-11">Week 2</span></div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-24">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 pb-16">
                                <h5 className="card-title">Profit and Loss (P&L)  </h5>
                                <span className="text-sm fw-medium text-secondary-light">Weekly project categorization analysis </span>
                            </div>
                            <div className="col-md-6">
                                <figure className="highcharts-figure">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptionsPL}
                                        ref={chartComponentRef}
                                    />
                                    <div className="text-center mt-16"><span className="rounded-pill bg-light-100 text-dark fw-bold radius-8 px-20 py-11">Week 1</span></div>
                                </figure>
                            </div>
                            <div className="col-md-6">
                                <figure className="highcharts-figure">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptionsPL}
                                        ref={chartComponentRef}
                                    />
                                    <div className="text-center mt-16"><span className="rounded-pill bg-light-100 text-dark fw-bold radius-8 px-20 py-11">Week 2</span></div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-24">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 pb-16">
                                <h5 className="card-title">Project Manager Performance  </h5>
                                <span className="text-sm fw-medium text-secondary-light">Weekly project categorization analysis </span>
                            </div>
                            <div className="col-md-6">
                                <UCLTopScorersChart week="Week 1" />
                            </div>
                            <div className="col-md-6">
                                <UCLTopScorersChart week="Week 2" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card mt-24">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 pb-16">
                                <h5 className="card-title">Milestones based delivery</h5>
                                <span className="text-sm fw-medium text-secondary-light">Weekly project categorization analysis</span>
                            </div>
                            {milestonesData.map((milestone, index) => (
                                <div className="col-md-12 mb-24" key={index}>
                                    <div className="MilestoneLable">
                                        <h5 className="card-title mb-0">{milestone.title}</h5>
                                        <span className="text-sm fw-medium text-secondary-light">{milestone.duration}</span>
                                    </div>
                                    <div className="progress mt-4 mb-0">
                                        <div
                                            className={`progress-bar ${index === 0 ? 'progress-bar-green' : index === 1 ? 'progress-bar-red' : 'progress-bar-info'}`}
                                            style={{ width: `${milestone.progress}%` }}
                                        >
                                            <span>&#8377; {milestone.amount}</span>
                                            <div className="progress-value">
                                                <img src="/assets/images/target-symbol.svg" className="img-fluid" alt="target-symbol" />
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="Listgrid-5">
                                        {[1000, 2000, 3000, 4000, 5000].map((value, valueIndex) => (
                                            <li key={valueIndex}>&#8377; {value}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
            <OrangeCategory />
        </>
    );
};

export default MyAudit;
