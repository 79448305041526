import { useCallback, useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { shallowEqual, useSelector } from "react-redux";
import FormikInput from "src/components/formik/FormikInput";
import FormikTextarea from "src/components/formik/FormikTextarea";
import { OAD_CMD_YupSchema } from "./ViewPropTypes";
import { toast } from 'react-toastify';
import { projectCMDSaveAPI, projectOADSaveAPI } from "src/api/ProjectApi";

const Render = (props) => {
    const {
        buttonText,
        values,
        isSubmitting,
        isValid, 
        dirty,   
        costLabel,  // New prop for dynamic label
    } = props;
    return (
        <>
            <Form autoComplete="disabled">
                <div className="row gy-3">
                    <div className="col-12">
                        <label className="form-label">{costLabel}</label> {/* Dynamic label */}
                        <div className="icon-field">
                            <FormikInput
                                name="value"
                                type="text"
                                className="form-control"
                                placeholder="0.00"
                            />
                            <span className="icon rupeeInr">
                                <i className="mdi mdi-currency-inr"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-12">
                        <label className="form-label">Comment</label>
                        <FormikTextarea
                            name="description"
                            className="form-control"
                            rows={4}
                            cols={50}
                            placeholder="Enter Comment..."
                            defaultValue={""}
                        />
                    </div>  
                    <div className="col-12">
                        <div className="d-flex gap-4 justify-content-between">
                            <button type="button" className="btn radius-8 px-20 py-11 w-100  btn-outline-neutral-900" data-bs-dismiss="modal" aria-label="Close">Back</button>
                            <button type="submit" 
                                disabled={!isValid || !dirty || isSubmitting} 
                                className="btn btn-primary radius-8 px-20 py-11 w-100" data-bs-dismiss="modal" aria-label="Close">{buttonText}</button>
                        </div>
                    </div>
                </div> 
            </Form>
        </>
    );
};

const OADAndCMDSavePopup = ({projectSearch}) => {
    const { OAD_CMD_Details } = useSelector((state) => state.ProjectReducer, shallowEqual);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const { _id: userId } = user;
    
    const [headingText, setHeadingText] = useState('Add OAD Cost');
    const [buttonText, setButtonText] = useState('Add');
    const [costLabel, setCostLabel] = useState('Enter your unique cost to add'); // Dynamic label

    const defaultProps = { value: null, comment: "", projectId: OAD_CMD_Details?.project?._id };

    useEffect(() => {
        if (OAD_CMD_Details.type === "OAD") {
            if (OAD_CMD_Details.mode === "add") {
                setHeadingText('Add OAD Cost');
                setButtonText('Add');
                setCostLabel('Enter your unique OAD cost to add');
            } else {
                setHeadingText('Remove OAD Cost');
                setButtonText('Remove');
                setCostLabel('Enter your unique OAD cost to remove');
            }
        } else {
            if (OAD_CMD_Details.mode === "add") {
                setHeadingText('Add CMD Cost');
                setButtonText('Add');
                setCostLabel('Enter your unique CMD cost to add');
            } else {
                setHeadingText('Remove CMD Cost');
                setButtonText('Remove');
                setCostLabel('Enter your unique CMD cost to remove');
            }
        }
    }, [OAD_CMD_Details]);

    const onSubmit = useCallback(async (values, formikUtils) => {
        try {
            values.by = userId;
            values.type = OAD_CMD_Details.mode.toLowerCase();
            const saveAPI = OAD_CMD_Details.type === "OAD" ? projectOADSaveAPI : projectCMDSaveAPI;
            const response = await saveAPI(values);
            toast.success(response.data.message);
        } catch (error) {
            toast.error("An error occurred while saving.");
        } finally {
            formikUtils.setSubmitting(false);
            formikUtils.resetForm();
            projectSearch();
        }
    }, [OAD_CMD_Details.type, OAD_CMD_Details.mode, userId, projectSearch]);

    return (
        <>
            <div className="modal fade cusmodl" id="uniqueCostModual" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center pb-0 border-0"> 
                            <h6 className="mb-2 fw-bold text-lg mb-0">{headingText}</h6>   
                        </div>
                        <div className="modal-body">
                            <Formik
                                onSubmit={onSubmit}
                                enableReinitialize
                                initialValues={defaultProps}
                                validationSchema={OAD_CMD_YupSchema}
                            >
                                {(formikProps) => (
                                    <Render
                                        buttonText={buttonText}
                                        costLabel={costLabel} // Pass dynamic label
                                        {...formikProps}
                                    />
                                )}
                            </Formik>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    );
};

export default OADAndCMDSavePopup; 