const ClientStatusCounter = ({ groupCounter,projectStatus }) => {
    const statusData = [
      {
        title: "Total Clients",
        icon: <img src="assets/images/left-sidebar/total-groups.svg" className="img-fluid" alt="Total Clients" />,
        value: groupCounter.clientCount,
        borderClass: "Tclients-border"
      },
      {
        title: "Total Project",
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
            <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
            <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
          </svg>
        ),
        value: groupCounter.projectCount,
        borderClass: "Tproject-border"
      },
      {
        title: "Average P&L",
        icon: <img src="assets/images/left-sidebar/average.svg" className="img-fluid" alt="Average P&L" />,
        value: groupCounter.ProfitAndLoss,
        borderClass: "Average-border"
      },
      ...projectStatus.map((status) => ({
        title: status._id,
        icon: status._id === "Active" ? (
          <i className="mdi mdi-account mdi-24px text-success-600"></i>
        ) :(
          <i className="mdi mdi-account-off mdi-24px text-danger-600"></i>
        ),
        value: status.count,
        borderClass:status._id === "Active" ? "Active-border" : "Deactive-border",
      }))
      // {
      //   title: "Active",
      //   icon:  <i className="mdi mdi-account mdi-24px text-success-600"></i>,
      //   value: groupCounter.clientCount,
      //   borderClass: "Active-border"
      // },
      // {
      //   title: "Deactive",
      //   icon:  <i className="mdi mdi-account-off mdi-24px text-danger-600"></i>,
      //   value: groupCounter.clientCount,
      //   borderClass: "Deactive-border"
      // },
      // ...projectStatus.map((status) => ({
      //   title: status._id,
      //   icon: status._id === "Approved" ? (
      //     <img src="/assets/images/left-sidebar/active.svg" className="img-fluid" alt="Active" />
      //   ) : status._id === "Rejected" ? (
      //     <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-dash-circle" viewBox="0 0 16 16">
      //       <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
      //       <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"></path>
      //     </svg>
      //   ) : (
      //     <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
      //       <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"></path>
      //       <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"></path>
      //     </svg>
      //   ),
      //   value: status.count,
      //   borderClass: `${status._id}-border`
      // }))
    ];
  
    return (
      <div className="row row-cols-xxxl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-2 row-cols-1 gy-4 bgfnutrs">
        {statusData.map((status, index) => (
          <div className="col" key={index}>
            <div className={`card shadow-none h-100 totproild ${status.borderClass} rounded-0`}>
              <div className="card-body p-20">
                <div className="text-neutral-600 fw-bold mb-4">
                  {status.title}
                </div>
                <div className="d-flex align-items-center gap-2 text-neutral-900 fw-medium">
                  {status.icon} {status.value}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default ClientStatusCounter;
  