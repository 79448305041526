import { useCallback,useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getDeliveryDeviationAPI } from "src/api/ProductionApi";
import MonthPicker from "src/components/MonthPicker";
import SearchInput from "src/components/SearchInput";
import DeviationRow from "./DeviationRow";

const DeliveryDeviationContainer=()=>{
  const [tableData,setTableDate] = useState([]);
  const [searchedDelivery,setSearchedDelivery] = useState([]);
  const [dateCol,setDateCol] = useState([]);
    const {selectedMonth,selectedYear} = useSelector(state=>state.ProductionReducer,shallowEqual);
    const [where, setWhere] = useState({
      $expr: {
      $and: [
        { $eq: [{ $year: "$date" },selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] } 
      ]
    }}
  );
      
      const handleChangeDate = (date)=>{
        const year = date.getFullYear();  // Example year
        const month = date.getMonth()+1;  // Since month is 0-indexed in JavaScript, add 1
        setWhere({
          ...where,
          $expr: {
            $and: [
              { $eq: [{ $year: "$date" },year] },
              { $eq: [{ $month: "$date" }, month] } 
            ]
          }
        })
      }
      const search = useCallback(async()=>{
        const MDTDeliveryData = await getDeliveryDeviationAPI({where})
        setSearchedDelivery(MDTDeliveryData.data.data)
        setTableDate(MDTDeliveryData.data.data)
        const allDates = MDTDeliveryData.data.data.reduce((acc, item) => {
          const dates = item.durationsByDate.map(d => d.date);
          return acc.concat(dates);
        }, []);
        const dateCol = [...new Set(allDates)];
        setDateCol(dateCol)
      },[where])
      useEffect(() => {
        search()
      },[where])
    
      const handleSearchInputChange = (e) => {
        const searchKey = e.target.value.trim().toLowerCase(); // Normalize and trim search input
        if (searchKey.length === 0) {
          // If search key is empty, reset to full table data
          setSearchedDelivery(tableData);
        } else {
          // Filter based on the productionSlug field
          const filteredData = tableData.filter((production) => {
            return production?.productionSlug?.toLowerCase().includes(searchKey);
          });
          setSearchedDelivery(filteredData); // Set the filtered results
        }
      };
    return (
        <>
         <div>
      <div class="col-lg-12">
        <div class="card"> 
          <div class="card-header">
          <h2 class="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> MTD Delivery</h2> 
          <ul class="d-flex align-items-center gap-2"><li class="fw-medium"><a href="index-2.html" class="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Home</a></li><li>-</li><li class="fw-medium"> MTD Delivery </li></ul>
          <div className="d-flex align-items-center gap-4 mt-16">
              {/* Search start */}
              <SearchInput handleSearchInputChange={handleSearchInputChange}/>
                {/* Search End */}
                <MonthPicker handleChangeDate={handleChangeDate} />
          </div>
          </div>
          <div class="card-body">
          <h5 class="card-title mb-16">Client Delivery List</h5>
          <div className="table-responsive table-container">
      <table className="table basic-border-table mb-0 table-hover">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Project Code</th>
            <th scope="col">Domain</th>
            <th scope="col" className="text-center">Director</th>
            <th scope="col" className="text-center">OAD</th>
            <th scope="col" className="text-center">CMD</th>
            <th scope="col" className="text-center">Total Delivery</th>
            <th scope="col" className="text-center">Deviation(%)</th>
            <th scope="col" className="text-center">Closer Date</th>
            {
              dateCol.map((date)=>(<th scope="col" className="text-center">{date}</th>))
            }

          </tr>
        </thead>
        <tbody>
          {searchedDelivery.map((row, index) => (
           <DeviationRow row={row} dateCol={dateCol} index={index} search={search}/>
          ))}
        </tbody>
      </table>
    </div>
          </div>
        </div> 
      </div>
    </div>
        </>
    )
}
export default DeliveryDeviationContainer