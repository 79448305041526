
// import { createTheme } from "@mui/material/styles";
import moment from "moment";
export const isHtmlTag = (str) => {
  const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return htmlRegex.test(str);
};

export const isTruthy = (value) => {
  return value !== null && value !== undefined && value !== 0 && value !== "";
};

export const checkIfLink = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  if (urlRegex.test(message)) {
    return true;
  } else {
    return false;
  }
};
const imageExtensions = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "svg",
  "webp",
  "heic",
  "heif",
];
export const getFileType = (media) => {
  const fileUrl = media.url;

  if (
    (checkIfLink(media.message) && fileUrl === null) ||
    fileUrl === undefined
  ) {
    return "unknown";
  }
  if (fileUrl) {
    const extension = fileUrl.split(".").pop().toLowerCase();
    const extension2 = media?.fileType?.split("/")[1];
    if (
      imageExtensions.includes(extension) ||
      imageExtensions.includes(extension2)
    ) {
      return "image";
    } else if (
      extension === "mp3" ||
      extension === "wav" ||
      extension === "ogg" ||
      extension === "m4a" ||
      extension === "opus" ||
      extension === "blob"
    ) {
      return "audio";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mkv" ||
      extension === "mov"
    ) {
      return "video";
    } else if (
      extension === "pdf" ||
      extension === "doc" ||
      extension === "docx" ||
      extension === "txt" ||
      [
        "zip",
        "rar",
        "7z",
        "tar",
        "gzip",
        "gz",
        "docx",
        "xlsx",
        "csv",
        "ppt",
        "pptx",
        "json",
      ].includes(extension)
    ) {
      return "document";
    } else if (checkIfLink(media.message)) {
      return "link";
    } else {
      return "unknown";
    }
  }
};

export const formatDate = (timestamp) => {
  return moment.utc(new Date(timestamp)).format("DD/MM/YYYY");
};
export const formatTime = (timestamp) => {
  // return moment.utc(new Date(timestamp)).format("HH:MM:SS");
  return moment(timestamp).format("hh:mm A");
};
export const getDateFromDate = (date) => {
  return moment(date).format("DD");
};
export const getDayFromDate = (date) => {
  return moment(date).format("dd")[0]; // 'ddd' gives the abbreviated name of the day, e.g., 'Mon'
};
export const formatDateToMon = (dateString) => {
  const months = [
    "JAN",
    "FAB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  return `${day} ${month}  `;
};

function isSameDateTime(date, time) {
  const [timePart, amOrPm] = time.split(" ");
  const [hours, minutes] = timePart.split(":").map(Number);

  let adjustedHours = hours;
  if (amOrPm === "pm" && hours !== 12) {
    adjustedHours += 12;
  } else if (amOrPm === "am" && hours === 12) {
    adjustedHours = 0;
  }
  const [year, month, day] = date.split("-").map(Number);
  const reminderDate = new Date(year, month - 1, day, adjustedHours, minutes);
  const now = new Date();

  const timeDiff = reminderDate.getTime() - now.getTime();
  return timeDiff <= 0;
}

// export const theme = createTheme({
//   components: {
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           "& $notchedOutline": {
//             "& .MuiOutlinedInput-notchedOutline": {
//               borderWidth: 1,
//             },
//           },
//         },
//       },
//     },
//   },
// });

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const convertTimestampToDdMmYyyy = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
export const unAcceptedExtensions = [
  ".bat",
  ".cmd",
  ".com",
  ".msi",
  ".ps1",
  ".sh",
  ".bash",
  ".py",
  ".rb",
  ".pl",
  ".js",
  ".vbs",
  ".wsf",
  ".hta",
  ".docm",
  ".xlsm",
  ".pptm",
  ".dll",
  ".so",
  ".dylib",
  ".pif",
  ".cpl",
  ".sys",
  ".eml",
  ".msg",
];

export const makeUniqueById = (array) => {
  const map = new Map();
  array.forEach((obj) => map.set(obj._id, obj));
  return [...map.values()];
};
export const titleCase = (str, isCamel = false) => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  if (isCamel) return str.join("");
  else return str.join(" ");
};


export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};
export const getDeviation = (actualDelivery, targetDelivery) => {  
  if(targetDelivery===0) return 0
  if (targetDelivery === 0) {
    // Handle the case where targetDelivery is 0 to avoid division by zero
    return actualDelivery === 0 ? 0 : (actualDelivery > 0 ? Infinity : -Infinity);
  }
  return ((actualDelivery - targetDelivery) / targetDelivery).toFixed(2);
};

export const CurrentYear = () => {
  const year = new Date().getFullYear();
  return year;
};

export const sumIdleTimes = (data, halfdayCount) => {
  // Function to convert time string (HH:MM:SS) to total minutes
  function timeToMinutes(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 60 + minutes + (seconds / 60);
  }

  // Function to convert total minutes to hours and minutes string
  function minutesToHoursMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60); // Round to handle floating-point precision issues
    return `${hours}H:${minutes}M`;
  }

  // Total number of records
  const totalRecords = data.length;

  // Total allowed idle time in minutes based on records and half-day count
  const idleTimeAllowedPerRecord = 75;
  const idleTimeAllowedPerHalfDay = idleTimeAllowedPerRecord / 2;
  const totalAllowedIdleMinutes = (totalRecords - halfdayCount) * idleTimeAllowedPerRecord + halfdayCount * idleTimeAllowedPerHalfDay;

  // Sum all idle times
  const totalIdleMinutes = data.reduce((sum, item) => sum + timeToMinutes(item.idle), 0);

  // Convert total minutes to hours and minutes format
  const totalIdleTimeFormatted = minutesToHoursMinutes(totalIdleMinutes);
  const totalAllowedIdleTimeFormatted = minutesToHoursMinutes(totalAllowedIdleMinutes);

  return {
    totalIdleTime: totalIdleTimeFormatted,
    totalAllowedIdleTime: totalAllowedIdleTimeFormatted
  };
}
 
export const sumWorkingTimes = (data, halfdayCount) => {
  // Function to convert time string (HH:MM:SS) to total minutes
  function timeToMinutes(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 60 + minutes + (seconds / 60);
  }

  // Function to convert total minutes to hours and minutes string
  function minutesToHoursMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60); // Round to handle floating-point precision issues
    return `${hours}H:${minutes}M`;
  }

  // Total number of records
  const totalRecords = data.length;

  // Total allowed working time in minutes based on records and half-day count
  const workingTimeAllowedPerDay = 9 * 60; // 9 hours in minutes
  const workingTimeAllowedPerHalfDay = 4.5 * 60; // 4.5 hours in minutes
  const totalAllowedWorkingMinutes = (totalRecords - halfdayCount) * workingTimeAllowedPerDay + halfdayCount * workingTimeAllowedPerHalfDay;

  // Sum all working times
  const totalWorkingMinutes = data.reduce((sum, item) => sum + timeToMinutes(item.Time), 0);

  // Convert total minutes to hours and minutes format
  const totalWorkingTimeFormatted = minutesToHoursMinutes(totalWorkingMinutes);
  const totalAllowedWorkingTimeFormatted = minutesToHoursMinutes(totalAllowedWorkingMinutes);

  return {
    totalWorkingTime: totalWorkingTimeFormatted,
    totalAllowedWorkingTime: totalAllowedWorkingTimeFormatted
  };
}

// Function to calculate the total lag
export const calculateTotalLagByDateAndSum = (data) => {
  // Step 1: Group data by date
  const groupedData = data.reduce((acc, item) => {
    const date = new Date(item.date);    
    // Format date as YYYY-MM-DD to ignore the time component
    const normalizedDate = date.toISOString().split('T')[0];  
    if (!acc[normalizedDate]) {
      acc[normalizedDate] = []; // Initialize an array for each date
    }  
    acc[normalizedDate].push(item); // Add the item to the respective date group
    return acc;
  }, {});

  // Step 2: Calculate the total lag for each date
  const lagSumsByDate = Object.keys(groupedData).map((date) => {
    const totalLagForDate = groupedData[date].reduce((totalLag, item) => {
      const approvedDuration = item.approvedDurations !== undefined && item.approvedDurations !== null ? item.approvedDurations : 0;

      if (item.targetUnit === "Per Day") {
        const lagInDay = ((1 / item.target) * approvedDuration);
        return totalLag + lagInDay;
      } else {
        const lag = ((1 / 480) * approvedDuration);
        return totalLag + lag;
      }
    }, 0); // Start totalLag at 0 for each date

    // Divide the total lag for the date by 1 (if necessary)
    const lagDividedByOne = totalLagForDate - 1;

    return lagDividedByOne; // Return the result for each date
  });

  // Step 3: Sum all the lag values across dates
  const totalLagSum = lagSumsByDate.reduce((sum, lagForDate) => sum + lagForDate, 0);

  return totalLagSum.toFixed(2) ;
};

  // Function to generate a dynamic color based on the name
  export const generateColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // Creates a unique HSL color
    return color;
  };
  
  // Function to generate a contrasting text color (white or black)
  export const getContrastYIQ = (hexColor) => {
    const rgb = hexColor.match(/\d+/g); // Extract rgb values
    const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return yiq >= 128 ? '#000' : '#fff'; // If light, return black text; otherwise, white
  };

  export const hasOldEntries =(productionList)=> {
    const fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);    
    return productionList.some((entry) => {
        const entryDate = new Date(entry.date);
        return entryDate < fiveDaysAgo;
    });
}

