import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

const PendingAlert = () => {
    // pendingList,pendingList.pendingType="Task"
    const pendingList = useSelector((state) => state.DashboardReducer.pendingList);
    const navigate = useNavigate();
    const handleReminderLatter = () => {
        localStorage.setItem("remindMeLaterTime", new Date().getTime());
    }
    const handleRedirection = () => {
        if (pendingList.pendingType === "Task") {
            navigate("/pending-task-list")
        } else if (pendingList.pendingType === "Production") {
            navigate("/pending-production-list")
        }
    }
    return (
        <>
            <div className="modal fade" id="requestAccess" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md ">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <h5 className="fw-semibold mb-24 actbnred d-flex align-items-center gap-3 text-dark"><img
                                src="/assets/images/action-required-icon.svg" className="img-fluid" alt="" width={60} height={60} /> Action Required!!</h5>

                            <div className="d-flex justify-content-between align-items-center px-16 py-12 bgne00w1 mb-24">
                                <div className="T_P_tskText d-flex align-items-center gap-4">{pendingList.pendingType} Pending for Approval <span class="badge text-sm fw-semibold rounded-pill bg-warning-600 px-20 py-6 radius-4 text-white">Pending</span></div>
                                <div className="T_P_tsk mt-0">{(pendingList.pendingType == "Task") ? pendingList?.task?.length : pendingList?.production?.length}</div>
                            </div>
                            <button type="button" class="btn rounded-pill btn-primary-600 radius-8 px-20 py-11" data-bs-dismiss="modal" aria-label="Close"
                                onClick={handleRedirection}> Action Now</button>
                            <a href="#"
                                className="d-block remindme"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleReminderLatter}
                            >Remind me later
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PendingAlert