import { useState } from "react";
import { productionApproveUpdate, productionRejectionUpdate } from "src/api/ReportsAPI";

const ApprovalModal=({
    selectedStatuses,
    production,
    getProjectProductionData
})=>{
 const remainDuration = production.totalDurations-((production?.approvedDurations||0)+(production?.rejectedDurations||0))
    const [approvedCount, setApprovedCount] = useState(0);
    const handleSubmit = async () => {
        if(approvedCount===0){
          alert('Please Enter Count')
          return
        }
        let data = { }
        if(selectedStatuses === 'Approved'){
            data = {
            approvedDurations: approvedCount
          }
        }
        else
        {
          data = {
            rejectedDurations: approvedCount
          }
        }
        try {
            let productionId = production._id;
            if(selectedStatuses === 'Approved'){
                 await productionApproveUpdate({ productionId, data })
            }
            else{
                await productionRejectionUpdate({ productionId, data })
            }      
            setApprovedCount(0);   
          getProjectProductionData(productionId)
        } catch (error) {
          console.log('error from approved count', error);
        }
      };
      const handleChange = (e) => {
        const value = e.target.value;        
        // Check if the value exceeds the remainDuration
        if (value > remainDuration) {
          alert('Max count is ' + remainDuration);
          // Revert the input field back to the previous valid value (remainDuration or previously approved value)
          e.target.value = remainDuration; // This will visually reset the input value in the field
          setApprovedCount(remainDuration); // Ensure the state also reflects the valid value          
          return;
        }        
        // Otherwise, set the valid input value to the state
        setApprovedCount(value);
      };
   return (
    <div class="modal fade" id="statusModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display: "none"}} aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-between">
                    <h5 class="modal-title" id="exampleModalLabel">{selectedStatuses==="Approved"?"Approval":"Rejection"} Count Max({remainDuration})</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"></path>
                        </svg>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="d-flex align-items-center gap-3"> 
                    <input 
                        type="text" 
                        value={approvedCount}
                        placeholder={`Enter ${selectedStatuses==="Approved"?"Approval":"Rejection"} Count`}
                        onChange={handleChange} 
                        class="form-control"
                     />
				     <button 
                      class="btn btn-primary-600 radius-8 px-20 py-11"
                      onClick={handleSubmit}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                     >Save</button></div>
                </div>
            </div>
        </div>
    </div>
   )
}
export default ApprovalModal