import React, { useState } from 'react';
import BackProjectButton from 'src/components/BackProjectButton';
import TopTabs from 'src/containers/project-container/View/TopTabs';

const Documentation = ({ item, index }) => {
    // State to manage the selected dropdown value for each row
    const [selectedValue, setSelectedValue] = useState("Action");

    // Function to handle dropdown item click
    const handleSelect = (value) => {
        setSelectedValue(value);
    };

    
    const HeadingDocumentation = "Hours Invested and Cost of Each Project";

    const tableDocumentation = [
        {
            uploadedby: `<div class="d-flex align-items-center gap-2">
        <span class="img imgsSet me-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="tooltip-dark" data-bs-title="Amrik Singh">
          <span class="initials d-flex justify-content-center align-items-center w-100 h-100" style="background-color: rgb(154, 217, 38); color: rgb(0, 0, 0);">A</span>
        </span>
        <div class="flex-grow-1">
          <h6 class="text-md mb-0 fw-medium text-primary-600">Amrik Singh</h6>
        </div>
      </div>`,
            type: 'Agreement',
            //   view: '',
            uploadat: '2024-09-30',
            description: 'View',
        }
    ];

    return (
        <>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">
                            <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                <BackProjectButton />
                            </h2>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Documentation</li>
                            </ul>
                            <TopTabs />
                            <div className="table-responsive table-container mt-24">
                                <table className="table basic-border-table mb-0 table-hover">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Uploaded By</th>
                                            <th>Document Type</th>
                                            <th className='text-center'>View Document</th>
                                            <th className='text-center'>Upload At</th>
                                            <th className='text-center'>Description</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableDocumentation.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td dangerouslySetInnerHTML={{ __html: item.uploadedby }} /> {/* Render HTML */}
                                                <td>{item.type}</td>
                                                <td className='text-center'> <button
                                                    className="btn btn-primary text-sm rounded-5"
                                                    onClick={() => console.log('View button clicked!')}
                                                ><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-download" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" /><path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" /></svg>
                                                </button></td>
                                                <td className='text-center'>{new Date(item.uploadat).toLocaleDateString()}</td>
                                                <td className='text-center'><button className="btn btn-primary text-sm" data-bs-toggle="modal" data-bs-target="#ViewdescriptionModual">{item.description}</button></td>
                                                <td className='text-center'> <div className="dropdown">
                                                    <button
                                                        className="btn btn-primary-600 not-active px-12 py-6 text-sm dropdown-toggle toggle-icon"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        {selectedValue} {/* Display selected dropdown value */}
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <a
                                                                className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                                                href="#"
                                                                onClick={() => handleSelect("Active")}
                                                            >
                                                                Active
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                                                href="#"
                                                                onClick={() => handleSelect("Inactive")}
                                                            >
                                                                Inactive
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="modal fade cusmodl" id="ViewdescriptionModual" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Description</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
                        <div className="modal-body">
                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documentation;
